.page-complete-reg {
  h3 {
    margin-bottom: 0;
  }

  .small {
    font-size: $body-font-size-small;
  }

  .info-text {
    font-size: $body-font-size-small;
    color: #000;
    margin: 0;
    padding: 0;
    padding: 0 0 0.3rem 0;
    position: relative;

    .guidaTaglie {
      color: $color-text !important;
      font-style: italic;
      position: absolute;
      top: 0;
      right: 0;
    }
    &.mandatory {
      color: $color-text !important;
      font-style: italic;
    }
  }

  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left !important;
  }

  .text-right {
    text-align: right !important;
  }

  .meeting-types-wrapper {
    padding-top: 1rem;
    .meeting-types {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-evenly;

      .checkbox-row {
        #outOfHomeCheckbox,
        #modernTradeCheckbox {
          margin-right: 1rem;
        }
      }
    }
  }

  .checkbox-row {
    a {
      text-decoration: underline;
      color: $color-primary !important;
    }
  }

  [class*="css-"][class$="-container"] {
    padding-top: 0.7rem;
    [class*="css-"][class$="-control"] {
      background-color: #ffffff;
      border: 0px solid #e6e8ea;
      border-radius: 0.2rem;
      font-family: "HelveticaNeue", sans-serif !important;
      box-shadow: 0 0 0 0 #fff !important;
      margin: 0;

      [class*="css-"][class$="-ValueContainer"] {
        font-size: 1rem;
        line-height: 1rem;
      }

      [class*="css-"][class$="-placeholder"] {
        color: #6f7072;
        margin: 0;
        padding-left: 0.5rem;
        font-size: 1rem;
        // line-height: .8rem;
        // padding: 0 1rem 0 0;
        // margin-top: -10px;
      }

      [class*="css-"][class$="-singleValue"] {
        color: #6f7072 !important;
        padding: 0;
        margin: 0;
        padding-left: 0.5rem;
      }

      [class*="css-"][class$="-indicatorSeparator"] {
        display: none;
      }

      [class*="css-"][class$="-menu"] {
        div {
          border: 1px solid red;
        }
      }
      svg {
        fill: $color-primary;
      }
    }
  }

  .field {
    &.altpopup {
      position: relative;

      label {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .bold {
    font-weight: bold;
  }

  .help-link {
    padding: 0;
    a {
      color: $color-text !important;
      font-style: italic;
      font-size: $body-font-size-small;
    }

    .modal-content {
      h2 {
        text-align: left;
        color: #000;
      }
      p {
        text-align: left;
      }
      a {
        font-style: normal;
        font-size: 1rem;
        color: $color-primary !important;
      }
    }
  }

  .address-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: stretch;

    .address {
      flex-basis: 85%;
      margin-right: 1rem;
    }

    .nr {
      flex-basis: 15%;
    }

    .city {
      flex-basis: 65%;
      margin-right: 1rem;
    }

    .zip {
      flex-basis: 20%;
      margin-right: 1rem;
    }

    .prov {
      flex-basis: 15%;
    }
  }

  .field {
    position: relative;

    .error-message {
      position: absolute;
      top: 1.3rem;
      left: 1rem;
      font-size: 0.8rem;
      overflow: hidden;
      white-space: nowrap;
    }

    &.error {
      border: 2px solid $color-primary;
    }
  }
}
