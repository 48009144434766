body {
  margin: 0;
  font-family: "HelveticaNeue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


@font-face {
  font-family: "HelveticaNeue";
  src: url("./assets/fonts/HelveticaNeue.ttf");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "HelveticaNeue";
  src: url("./assets/fonts/HelveticaNeueMed.ttf");
  font-style: normal;
  font-weight: medium;
  font-display: swap;
}
@font-face {
  font-family: "HelveticaNeue";
  src: url("./assets/fonts/HelveticaNeueBd.ttf");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@import url("https://use.typekit.net/omg5bkm.css");