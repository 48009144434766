// PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY
// PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY
// PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY

.page-plenary {
  &.studi {
    .page-container {
      background-image: url('/img/02-studi/back-plenaria.jpg') !important;
    }
  }

  &.enterprise {
    .page-container {
      background-image: url('/img/01-enterprise/back-plenaria.jpg') !important;
    }
  }

  &.swp {
    .page-container {
      background-image: url('/img/02-swp/back-plenaria.jpg') !important;
    }
  }

  .col-10.col-white-wrapper {
    flex-basis: 75%;
    max-width: 75%;
  }

  .plenary-left-navigation-wrapper {
    .plenary-icon-left {
      cursor: pointer;
      color: white;
      // font-weight: 600;
      .disabled {
        color: #afafaf;
      }
      &.deactivated {
        color: $color-darkblue;
        cursor: not-allowed;
      }
    }
  }

  .plenary-chat-wrapper {
    background-color: $color-white;

    .advisorItem {
      font-size: $body-font-size-xxsmall;
      line-height: $body-font-size-xxsmall * 1.3;
      font-weight: 600;

      &:hover {
        cursor: pointer;
        color: $color-primary;

        .title {
          color: $color-primary;
        }
        .arrow {
          background-image: url('/img/nav-arrow-right.svg');
          background-repeat: no-repeat;
          background-position: center right;
        }
      }

      .title {
        font-weight: 300;
        color: $color-mediumgray;
      }

      .profilePic {
        .status {
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 50%;
          width: 0.5rem;
          height: 0.5rem;
          background-color: $color-text;

          &--active {
            @extend .status;
            background-color: $color-primary;
          }
        }
        img {
          max-width: 100%;
          border-radius: 50%;
        }
      }
    }
  }

  .tappo {
    img {
      width: 100%;
      margin-top: 2rem;
    }
  }

  .themes-wrapper {
    h2 {
      color: #afafaf;
      &.enabled {
        color: $color-white;
      }
    }
    .themes-list {
      overflow-y: hidden;
      overflow-x: auto;

      .themes-list-scroller {
        .theme-toggle {
          text-decoration: none;
          text-transform: uppercase;
          text-align: center;
          line-height: $body-font-size-xxsmall * 1.3;
          width: 8rem;
          margin-right: 1rem;

          &.disabled {
            color: #afafaf;
          }
          &.enabled {
            color: $color-white;
          }

          .theme-icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 8rem;

            img {
              height: 4.5rem;
            }
          }

          .theme-label {
            width: 8rem;
            padding: 0;
            margin-top: 0.5rem;
            font-weight: 400;

            &.enable {
              color: white;
            }
            &.disable {
              color: #afafaf;
            }
          }
        }
      }
    }
  }

  // FULL CHAT CHAT CHAT CHAT CHAT CHAT
  .white-box {
    .left-box {
      background-color: $color-white;
      h2 {
        color: $color-primary;
      }

      .advisorItem {
        &:hover {
          cursor: pointer;
          color: $color-primary;

          .title {
            color: $color-primary;
          }
          .arrow {
            background-image: url('/img/blue-arrow-left.svg');
          }
        }
      }
    }

    .right-box {
      color: $color-white;
      background-color: $color-primary;
    }

    &.mobile-chat {
      background-color: $color-primary;
    }

    &.stanby {
      min-height: 20rem;
      padding: 2rem 1rem;
      color: #000;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      h1 {
        color: #000;
        font-size: 2rem;
        font-weight: bold;
      }
      p {
        font-size: 1.8rem;
      }
    }
  }
}

.page-wrapper {
  .page-container {
    .page-content {
      .center-area {
        &.standby-themed {
          img {
            margin: 80px auto;
            width: 80%;
            max-width: 500px;
          }
          .col-white-wrapper {
            padding-bottom: 200px;
          }

          &:before {
            display: none;
          }

          .tappo-row1 {
            line-height: 4rem;
            font-size: 4rem;
            text-align: center;
            padding: 1rem;
          }

          .tappo-row2 {
            line-height: 4rem;
            font-weight: bold;
            font-size: 4rem;
            text-align: center;
            padding: 1rem;
          }

          .tappo-row3 {
            line-height: 4rem;
            font-size: 4rem;
            text-align: center;
            padding: 1rem;
          }
        }
      }
    }
  }

  [class*='css-'][class$='-container'] {
    color: #000 !important;
    padding-bottom: 0.5rem;
    font-size: 1rem;

    [class*='css-'][class$='-control'] {
      background-color: #ffffff;
      border: 0px solid #e6e8ea;
      border-radius: 0.2rem;
      font-family: 'HelveticaNeue', sans-serif !important;
      box-shadow: 0 0 0 0 #fff !important;
      margin: 0;

      [class*='css-'][class$='-ValueContainer'] {
        font-size: 1rem;
        line-height: 1rem;
        color: #000 !important;
      }

      [class*='css-'][class$='-placeholder'] {
        color: #000 !important;
        margin: 0;
        padding-left: 0.5rem;
        font-size: 1rem;
      }

      [class*='css-'][class$='-singleValue'] {
        color: #000 !important;
        padding: 0;
        margin: 0;
        padding-left: 0.5rem;
      }

      [class*='css-'][class$='-indicatorSeparator'] {
        display: none;
      }

      [class*='css-'][class$='-menu'] {
        margin-top: 0;
        * {
          color: #000 !important;
        }
      }
      svg {
        fill: $color-primary;
      }
    }
  }
}

.video-wrapper {
  //margin-bottom: -5px;
}

.plenary-agenda {
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  max-height: 100%;
  width: 20%;
  min-width: 9rem;
  z-index: 99;

  .event-tab {
    color: $color-primary;

    .tab-title {
      font-size: $body-font-size-xxsmall;
      line-height: $body-font-size-xxsmall * 1.3;
      font-weight: 600;
      width: 100%;
    }

    .tab-date {
      font-size: $body-font-size-xxsmall;
      line-height: $body-font-size-xxsmall * 1.3;
      font-weight: 300;
      width: 100%;
    }
  }

  .rainbow {
    [class*='rc-'] {
      height: 0.5rem;
    }
  }

  .plenary-agenda-list {
    overflow-y: auto;
    height: 100%;
    max-height: 22.4rem;
  }

  .plenary-agenda-footer {
  }
}

.follow-up-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  background-color: $color-overlay;

  .rainbow {
    [class*='rc-'] {
      height: 0.5rem;
    }
  }

  .follow-up-list {
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .follow-up-item {
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    line-height: $body-font-size-xxsmall * 1.3;

    color: #144390;

    .follow-up-item-icon {
      min-height: 4.5rem;
    }
  }
}

.video-moddal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  background-color: rgba(00, 77, 107, 0.6);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  video {
    width: 60%;
    max-width: 60%;
    outline: none;
  }

  .modal-close {
    position: absolute;
    top: 4rem;
    right: 4rem;

    &:hover {
      cursor: pointer;
    }
  }
}

.liveDisabled {
  font-family: 'HelveticaNeue', sans-serif;
  color: $color-white;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0.5rem;
}

.questionsh-wrapper {
  display: flex;
  flex-wrap: nowrap;

  .qea {
    background-color: $color-primary;
    flex-basis: 75%;
    width: 75%;
    display: flex;
    align-items: stretch;
    padding: 0;
  }

  .lang-picker {
    background-color: #000;
    flex-basis: 25%;
    width: 25%;
    display: flex;
    flex-direction: row;

    color: #fff;

    .lang-picker-wrapper {
      // border: 1px solid red;
      width: 100%;
      flex-basis: 100%;
      padding: 0.5rem;

      .lang-picker-title {
        // border: 1px solid green;
        font-weight: bold;
        margin-bottom: 1.5rem;
        font-size: 0.95rem;

        img {
          max-width: 24px;
          margin-left: 0.5rem;
          margin-bottom: 2px;
          vertical-align: middle;
        }
      }

      .lang-picker-item {
        // border: 1px solid yellow;
        padding-bottom: 1rem;
        font-size: 0.95rem;

        cursor: pointer;
        img {
          max-width: 15px;
          margin-right: 0.5rem;
          margin-bottom: 2px;
          vertical-align: middle;
        }
        strong {
          font-weight: bold !important;
        }
      }
    }
  }

  .live {
    background-color: #8e1230;
  }

  .title {
    font-family: 'HelveticaNeue', sans-serif;
    color: $color-primary;
    font-weight: 800;
    font-size: 1.9rem;
    line-height: 2rem;
    padding: 0.4rem 0;
    margin: 0;
  }

  .help {
    font-family: 'HelveticaNeue', sans-serif;
    color: $color-text;
    font-size: $body-font-size-small !important;
    padding-left: 3.6rem;
  }

  .crMessage {
    font-family: 'HelveticaNeue', sans-serif;
    color: #e9573d;
    font-size: $body-font-size;
    border: 2px solid #e9573d;
    border-radius: 0.5rem;
    padding: 1rem;
    margin: 0.5em 0;
  }

  .crLabel {
    font-family: 'HelveticaNeue', sans-serif;
    font-size: $body-font-size;
  }

  .center {
    text-align: center;
  }

  .cancelBox {
    background: $color-white;
    border: 1px solid $color-gray;
    border-radius: 0.5rem;

    font-family: 'HelveticaNeue', sans-serif;
    color: $color-primary;
    font-size: $body-font-size;

    padding: 0.5rem;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);

    button {
      text-transform: none;
    }
  }

  .action-container {
    padding: 2rem 3rem;
    flex-basis: 50%;
    width: 50%;
    display: flex;
    align-items: stretch;

    &.neutral {
      // Nothing
    }

    &.opened {
      flex-basis: 100%;
      flex: 1 0 auto;
      width: 100%;
      padding: 0;
    }

    &.closed {
      width: 0;
      overflow: hidden;
      padding: 0;
    }
  }

  .action-content {
    color: $color-white;
    flex-flow: row nowrap;
    .container-close-button {
      flex-basis: 5%;
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 1rem;
      }

      &:hover {
        cursor: pointer;
      }

      .closeLabel {
        font-size: 0.45rem;
        text-transform: uppercase;
      }
    }

    .button--submit_small.reverse {
      color: $color-primary;

      &:hover {
        color: $color-white;
        background-color: $color-primary;
        border-color: $color-white;
      }
    }
    .content {
      flex-basis: 100%;
      padding: 0;
      display: flex;
      flex-flow: row nowrap;

      .left {
        display: flex;
        align-items: stretch;
      }

      .title {
        color: $color-white;
        //font-weight: normal;
      }

      .help {
        color: $color-white;
        font-size: $body-font-size;
      }

      .error {
        color: $color-white;
        font-size: $body-font-size-small;
        line-height: $body-font-size;
      }

      .queue {
        color: $color-white;
        font-size: $body-font-size;
        text-align: center;
        padding: 0.5rem 0;

        span {
          color: $color-primary;
          font-size: 1.9rem;
          padding: 0 0.2rem;
        }
      }

      .info-container {
        display: flex;
        flex-direction: column;
        //justify-content: center;
        align-items: flex-start;
        padding: 0;

        .icon {
          max-height: 2rem;
          vertical-align: middle;
          margin-right: 0.5rem;
        }
      }

      .question-thankyou {
        font-family: 'HelveticaNeue', sans-serif;
        color: $color-white;
        text-align: center;
        font-weight: normal;
        font-size: 1.3rem;
        line-height: 1.6rem;
        padding: 3.65rem 0;
        margin: 0;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
    }
  }

  .start-button {
    @include transitionall(0.2s);
    background: $color-white;
    border: 1px solid $color-gray;
    border-radius: 0.5rem;
    padding: 1rem;
    display: flex;
    flex: 1 1 auto;
    flex-flow: row wrap;
    min-height: 6rem;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);

    &:hover {
      box-shadow: 0px 0px 20px rgba(8, 8, 8, 0.35);
      cursor: pointer;
    }

    .icon {
      text-align: center;
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: center;
    }

    .content {
      text-align: center;
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: center;
    }
  }
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.countdown-wrapper {
  min-height: 26.2rem;

  .coutdown-row {
    height: 100%;
  }

  .digitContainer {
    color: $color-white;
    font-size: 3.75rem;
    width: 8rem;
    font-family: 'HelveticaNeue';

    .digitLabel {
      font-size: 1.5rem;
      font-weight: normal;
    }
  }

  .countdown-image {
    width: 228px;
  }
}

.wistia_responsive_padding {
  padding: 56.25% 0 0 0;
  position: relative;

  .wistia_responsive_wrapper {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    .wistia_embed {
      height: 100%;
      position: relative;
      width: 100%;

      .wistia_swatch {
        height: 100%;
        left: 0;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        transition: opacity 200ms;
        width: 100%;

        img {
          filter: blur(5px);
          height: 100%;
          object-fit: contain;
          width: 100%;
        }
      }
    }
  }
}
