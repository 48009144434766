.white-box {
  background: $color-white;
  box-shadow: 0px 5px 15px $color-shadow;
  background: #005388;// rgb(0, 145, 211);
  border:4px solid white;
  border-radius: 20px;
  box-shadow: 0px 5px 15px $color-shadow;
  padding: 20px;

}

// COLORS COLORS COLORS COLORS COLORS COLORS COLORS COLORS COLORS
.primary {
  color: $color-primary;
}
.primary-bg {
  background: $color-primary;
}

.magenta {
  color: $color-magenta;
}
.magenta-bg {
  background: $color-magenta;
}

.white {
  color: $color-white;
}
.white-bg {
  background: $color-white;
}

.purple {
  color: $color-purple;
}
.purple-bg {
  background: $color-purple;
}
.yellow {
  color: $color-yellow;
}
.yellow-bg {
  background: $color-yellow;
}
.orange {
  color: $color-orange;
}
.orange-bg {
  background: $color-orange;
}

// FONT UTILITIES FONT UTILITIES FONT UTILITIES FONT UTILITIES FONT UTILITIES
.xlarge {
  font-size: $body-font-size-xlarge;
  line-height: $body-font-size-xlarge * 1.3;
}
.large {
  font-size: $body-font-size-large;
  line-height: $body-font-size-large * 1.3;
}
.normal {
  font-size: $body-font-size;
  line-height: $body-font-size * 1.3;
}
.small {
  font-size: $body-font-size-xsmall;
}

.xxsmall {
  font-size: $body-font-size-xxsmall;
}

.thin {
  font-weight: 300;
}

// TYPOGRAPHY TYPOGRAPHY TYPOGRAPHY TYPOGRAPHY TYPOGRAPHY TYPOGRAPHY TYPOGRAPHY

.text-title {
  font-family: "HelveticaNeue", sans-serif;
  font-size: 1.5rem; //24px
  //line-height: 6.3125rem; //101px
  line-height: 2rem; //32px
  font-weight: 350;
}

.text-body {
  font-family: "HelveticaNeue", sans-serif;
  color: $color-bodytext;
  font-size: 0.825rem; //13px
  line-height: 1.125rem; //18px
  font-weight: 300;
}

// RAINBOW RAINBOW RAINBOW RAINBOW RAINBOW RAINBOW RAINBOW RAINBOW RAINBOW
.rainbow-item {
  height: 1rem;
  flex: 1 1 0;
}
.rc-1 {
  @extend .rainbow-item;
  background-color: white;
}
.rc-2 {
  @extend .rainbow-item;
  background-color: white;
}
.rc-3 {
  @extend .rainbow-item;
  background-color: white;
}
.rc-4 {
  @extend .rainbow-item;
  background-color: white;
}
.rc-5 {
  @extend .rainbow-item;
  background-color: white;
}
.rc-6 {
  @extend .rainbow-item;
  background-color: white;
}
.rc-7 {
  @extend .rainbow-item;
  background-color: white;
}
