// HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL
// HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL
// HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL

.page-hall {
  &.studi {
    .page-container {
      background-image: url('/img/backgroundmmba1.jpg') !important;
    }
  }

  &.enterprise {
    .page-container {
      background-image: url('/img/backgroundmmba1.jpg') !important;
    }
  }

  &.swp {
    .page-container {
      background-image: url('/img/backgroundmmba1.jpg') !important;
    }
  }

  .animations-container {
    //border: 1px solid yellow;
    height: 100% !important;
    width: 100% !important;

    .animation-box {
      // border: 1px solid green;
      height: 100% !important;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-around;
      align-items: center;

      .top-center {
        padding: 2rem;
        // img {
        //   width: 19.687rem;
        //   max-width: 19.687rem;
        // }
        // position: absolute !important;
        // top: 5%;
        // left: 50% !important;
        // transform: translate(-50%, 0) !important;
      }
    }
    .animations-wrapper {
      flex-grow: 1;
      min-height: 400px;
      position: relative;
      width: 100%;

      .button-wrapper {
        position: relative;
        height: 100%;
        border: 1px solid black;
      }

      .animation {
        position: absolute;
      }

      .nav-button {
        // border: 1px solid green;
        background: transparent;
        position: relative;
        text-decoration: none;
        cursor: pointer;
        font-weight: bold;
        text-align: center;
        white-space: nowrap;
        color: #ffffff;
        font-size: 2rem;
        padding-left: 90px;

        @include transitionall(0.2s);

        &::before {
          content: ' ';
          display: block;
          width: 84px;
          height: 32px;
          // border-radius: 40px;#0091d3
          background-color: #ffffff;
          // border: 5px solid #f49b00;
          float: left;
          margin-right: 10px;
          position: absolute;
          top: 4px;
          left: 0;
        }

        &:hover {
          &::before {
            -webkit-animation: heartbeat 3s ease-in-out infinite both;
            animation: heartbeat 3s ease-in-out infinite both;
          }
        }
      }

      .studi {
        .nav-button {
          &::before {
            background-color: #0091d3;
          }
        }
      }
      .enterprise {
        .nav-button {
          &::before {
            background-color: #0091d3;
          }
        }
      }
      .swp {
        .nav-button {
          &::before {
            background-color: #ffffff;
          }
        }
      }
      // Help desk
      .helpdesk {
        top: 2%;
        left: 10%;
        // .nav-button {
        //   &::before {
        //     border: 5px solid #0091d3;
        //   }
        // }
      }

      // Plenaria
      .plenaria {
        top: 40%;
        left: 45%;
      }

      // Ospite
      .ospite {
        top: 75%;
        left: 5%;
        // .nav-button {
        //   &::before {
        //     border: 5px solid #c00b6c;
        //   }
        // }
      }

      // Agenda
      .agenda {
        top: 28%;
        left: 82%;
      }

      // // Chat
      // .chat {
      //   z-index: 9999;
      //   top: 6%;
      //   left: 73%;

      //   .nav-button {
      //     top: 70%;
      //     right: -7%;
      //   }

      //   &:hover {
      //     .nav-button {
      //       // background-color: darken($rc-4, 15%);
      //       // color: $color-white;
      //     }
      //   }
      // }
    }

    .animations-mob-wrapper {
      // border: 1px solid red;
      width: 100%;
      height: 100%;
      // display: flex;
      // flex-flow: row nowrap;
      // justify-content: flex-start;

      display: flex;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;

      // .agenda-mob, .ospite-mob, .plenaria-mob, .helpdesk-mob {
      //   width: 200px;
      //   height: 100%;
      //   border: 1px solid red;
      //   flex-grow: 1;
      // }

      div {
        scroll-snap-align: start;
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        // background-color: #0091d3;
        transform-origin: center center;
        transform: scale(1);
        transition: transform 0.5s;
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;

        .nav-button {
          background: transparent;
          position: relative;
          text-decoration: none;
          cursor: pointer;
          font-weight: bold;
          text-align: center;
          white-space: nowrap;
          color: #ffffff;
          font-size: 2rem;
          padding-left: 50px;
          padding-top: 10px;

          @include transitionall(0.2s);

          &::before {
            content: ' ';
            display: block;
            width: 40px;
            height: 40px;
            // border-radius: 40px;
            background-color: $color-white;
            // border: 5px solid #f49b00;
            float: left;
            margin-right: 10px;
            position: absolute;
            top: 0;
            left: 0;
          }

          &:hover {
            &::before {
              -webkit-animation: heartbeat 3s ease-in-out infinite both;
              animation: heartbeat 3s ease-in-out infinite both;
            }
          }
        }

        // &.ospite-mob {
        //   .nav-button {
        //     &::before {
        //       // border: 5px solid #c00b6c;
        //     }
        //   }
        // }

        // &.helpdesk {
        //   .nav-button {
        //     &::before {
        //       // border: 5px solid #0091d3;
        //     }
        //   }
        // }
        &.studi {
          .nav-button {
            &::before {
              background-color: #0091d3;
            }
          }
        }
        &.enterprise {
          .nav-button {
            &::before {
              background-color: #0091d3;
            }
          }
        }
        &.swp {
          .nav-button {
            &::before {
              background-color: #ffffff;
            }
          }
        }

      }
    }
  }

  .event-date {
    color: white;
    text-transform: uppercase;
    font-weight: 400;
    margin: 0.5rem 0;
  }
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  20% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  30% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  70% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  90% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  20% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  30% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  70% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  90% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

// @-webkit-keyframes heartbeat {
//   from {
//     -webkit-transform: scale(1);
//             transform: scale(1);
//     -webkit-transform-origin: center center;
//             transform-origin: center center;
//     -webkit-animation-timing-function: ease-out;
//             animation-timing-function: ease-out;
//   }
//   10% {
//     -webkit-transform: scale(0.91);
//             transform: scale(0.91);
//     -webkit-animation-timing-function: ease-in;
//             animation-timing-function: ease-in;
//   }
//   17% {
//     -webkit-transform: scale(0.98);
//             transform: scale(0.98);
//     -webkit-animation-timing-function: ease-out;
//             animation-timing-function: ease-out;
//   }
//   33% {
//     -webkit-transform: scale(0.87);
//             transform: scale(0.87);
//     -webkit-animation-timing-function: ease-in;
//             animation-timing-function: ease-in;
//   }
//   45% {
//     -webkit-transform: scale(1);
//             transform: scale(1);
//     -webkit-animation-timing-function: ease-out;
//             animation-timing-function: ease-out;
//   }
// }
// @keyframes heartbeat {
//   from {
//     -webkit-transform: scale(1);
//             transform: scale(1);
//     -webkit-transform-origin: center center;
//             transform-origin: center center;
//     -webkit-animation-timing-function: ease-out;
//             animation-timing-function: ease-out;
//   }
//   10% {
//     -webkit-transform: scale(0.91);
//             transform: scale(0.91);
//     -webkit-animation-timing-function: ease-in;
//             animation-timing-function: ease-in;
//   }
//   17% {
//     -webkit-transform: scale(0.98);
//             transform: scale(0.98);
//     -webkit-animation-timing-function: ease-out;
//             animation-timing-function: ease-out;
//   }
//   33% {
//     -webkit-transform: scale(0.87);
//             transform: scale(0.87);
//     -webkit-animation-timing-function: ease-in;
//             animation-timing-function: ease-in;
//   }
//   45% {
//     -webkit-transform: scale(1);
//             transform: scale(1);
//     -webkit-animation-timing-function: ease-out;
//             animation-timing-function: ease-out;
//   }
// }
