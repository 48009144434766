.page-voices {
    h1 {
        line-height: 2.8rem;;
    }
    h2 {
        text-align: center;
        line-height: 2rem;
    }
    .voices-container {
        padding: 1rem;
        margin: 0 auto !important;

        p {
            text-align: center;
            strong {
                font-weight: bold;
            }
            &.guidaVideo {
                text-align: right;
            }
        }

        .checkbox-row {
            margin-bottom: 2rem;
        }

        .voices-items-wrapper {
            padding-top: 2rem;
            border-top: 1px solid #eee;

            .voices-item {
                background-color: #000;
                padding: .5rem 1.5rem;
                border-radius: .5rem;
                margin-bottom: 1rem;
                p {
                    color: $color-white;
                    font-size: 1.2rem;
                    text-align: left;
                }
            }
        }
    }


    .guidaVideo {
        color: $color-text !important;
        font-style: italic;

        a {
            color: $color-text !important;
            font-style: italic;
            font-size: .9rem;
        }
    }

    input[type="file"] {
        -webkit-appearance: none !important;
        padding: .4rem;
        width: 75%;
        float: left;
    }

    button {
        width: 24%;
        float: right;
        padding: .6rem;

        &:disabled {
            color: $color-white;
            background-color: $color-gray;
            border: 1px solid $color-gray;
            cursor: auto;
        }
    }

    .message {
        color: $color-primary;
    }

    .clear {
        clear: both;
    }
}