
@media screen and (max-width: 1400px) { 


}

@media screen and (max-width: 1200px) {
  

} 


@media screen and (max-width: 1024px) {
  
  .tww-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .people-image {
    display: none !important;
  }  

  .top-header{
    .event-date{
     margin-bottom: .5rem;
    }
  }

  .page-login {
  }  
}

@media screen and (max-width: 996px) { 

  .tww-container {
    padding-top: .5rem;
    padding-bottom: 1rem;

    .tww-image-small {
      max-width: 14rem; //220px
    }
  }

  .top-header{
    .event-date{
     font-size: .8rem;
    }
  }

  

}

@media screen and (max-width: 768px) { 

  // LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN
  .page-login {
  }  

    // NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION
    .navigation-wrapper{
      .navigation-container{
        .nav-logo{
          padding: 1rem 1rem 0 1rem !important;
        }
      }

      .mobile-header{
        padding-bottom: 0.2rem !important;

        .userprofile-name{
          font-size: 1.125rem;
    
        }
      }

      .nav-navigation {
        .nav-item-container{
          padding-bottom: 1.2rem !important;

          .nav-item-handle{
            font-size: 1.312rem;
          }
        }
      }
    }



}

@media screen and (max-width: 575px) {
  // LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN
 .page-login {
  }

  // ITEMS LIST SCROLLABLE
  .items-list{
      width: 90vw;
      max-width: 90vw;
      overflow: auto;
      display: flex;

    .items-list-scroller{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      & > div{
        padding-top: 1rem;
        padding-bottom: 1.3rem !important;
        width: 8.5rem;
        position: relative;

        .arrow{
          transform: rotate(90deg);
          position: absolute;
          bottom: 0;
          left: 45%;
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }

}

// .people-image {
//   display: none !important;
// }  
