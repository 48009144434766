// NETWORKING NETWORKING NETWORKING NETWORKING NETWORKING NETWORKING NETWORKING NETWORKING NETWORKING NETWORKING
// NETWORKING NETWORKING NETWORKING NETWORKING NETWORKING NETWORKING NETWORKING NETWORKING NETWORKING NETWORKING
// NETWORKING NETWORKING NETWORKING NETWORKING NETWORKING NETWORKING NETWORKING NETWORKING NETWORKING NETWORKING

.page-chatroom {
  .center-area {
    justify-content: center!important;
  }

  .networking-wrapper {

    .chat-wrapper {
      padding-top: 20px;
      padding-bottom: 20px;

      .white-box {
        .left-box {
          background-color: $color-white;
          h2 {
            color: #0091d4;
          }
        }
        .right-box {
          color: $color-white;
          background-color: #0091d4;
        }

        &.mobile-chat {
          background-color: #0091d4;
        }
      }
    }
  }
}

.networking-container {
  text-align: left;
  border: 1px solid $color-gray;
  border-radius: 0.8rem;

  background-color: $color-white;

  .right-container {
    border: 1px solid $color-gray;
    border-radius: 0.8rem;
    padding: 1rem;
    background-color: $color-white;
  }
}

.participants-list {
  max-height: 25rem;
  overflow-y: auto;
}

.chat-list {
  min-height: 29rem;
  max-height: 29rem;
  overflow-y: auto;
}

.messages-list {
  $messages-list-height: 22rem;
  min-height: $messages-list-height;
  max-height: $messages-list-height;

  //Nascondiamo la scrollbar ma manteniamo scrollabile
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  //Sfumatura per nascondere i messaggi in alto
  mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 1) 95%,
    rgba(0, 0, 0, 0) 100%
  );
}

// User Row User Row User Row User Row User Row User Row User Row User Row User Row User Row
.advisorItem {
  font-size: $body-font-size-xxsmall;
  line-height: $body-font-size-xxsmall * 1.3;
  font-weight: 600;
  width: 100%;

  .arrow {
    min-width: 1rem;
  }

  &:hover {
    cursor: pointer;
    color: $color-primary;

    .title {
      color: $color-primary;
    }
    .arrow {
      min-width: 1rem;
      background-image: url("/img/blue-arrow-left.svg");
      background-repeat: no-repeat;
      background-position: center right;
    }
  }

  .title {
    font-weight: 300;
    color: $color-mediumgray;
  }

  .profile-pic {
    width: 2rem;
    .status {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      width: 0.5rem;
      height: 0.5rem;
      background-color: $color-text;
      z-index: 99;

      &--active {
        @extend .status;
        background-color: $color-primary;
      }
    }
    img {
      max-width: 100%;
      border-radius: 50%;
    }
  }
}

// Chatroom Row Chatroom Row Chatroom Row Chatroom Row Chatroom Row Chatroom Row Chatroom Row
.chatrow-wrapper {
  border-top: 1px solid $color-bodytext-alpha-4;
  padding: 1rem 0;

  font-family: "HelveticaNeue", sans-serif;
  color: $color-white;
  font-size: $body-font-size;

  .chat-profilepic {
    padding: 0.5rem 0;
    max-width: 4rem;
    cursor: pointer;
    position: relative;
  }

  .chat-name {
    padding-left: 1rem;

    font-family: "HelveticaNeue", sans-serif;
    font-weight: normal;
    font-size: 1.2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
  }
  .chat-tools {
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &.public {
    background-color: $color-primary;

    .chat-name {
      color: $color-white;
    }

    .unread-messages-badge {
      background-color: $color-white;
      color: #0091d4;
    }
  }

  &:not(.public) {
    .message-author {
      display: none;
    }
  }
}

.unread-messages-badge {
  border-radius: 0.75rem;
  background-color: $color-white;
  color: #0091d4;
  font-size: $body-font-size-small;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
}

.chat-tools-toggle {
  width: 1.5rem;
  height: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 0.5rem;
  position: relative;
  overflow: visble;

  &:hover {
    cursor: pointer;
  }

  .toolbox {
    border-radius: 0.2rem;
    border: 1px solid $color-gray;
    background-color: $color-white;

    padding: 1rem;

    position: absolute;
    top: -1rem;
    left: -6.5rem;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
}

// Chatroom Item Chatroom Item Chatroom Item Chatroom Item Chatroom Item Chatroom Item Chatroom Item
.chatroom-wrapper {
  .back-button {
    font-family: "HelveticaNeue", sans-serif;
    font-weight: 350;
    color: $color-white;
    font-size: 1.5rem;

    background-color: transparent;
    border: 0;
    padding: 0.5rem 0 2rem;

    &:hover {
      cursor: pointer;
    }

    img {
      margin-right: 0.5rem;
    }
  }

  .chatroom-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
  }
  .chatroom-header {
    // display: flex;
    // flex-flow: row nowrap;
    // justify-content: flex-start;
    // padding-left: 0.5rem;

    // .chat-profilepic {
    //   padding: 0.5rem 0;
    //   max-width: 3rem;
    // }

    // .chat-name {
    //   padding-left: 1rem;

    //   font-family: "HelveticaNeue", sans-serif;
    //   font-weight: normal;
    //   font-size: 1.2rem;

    //   display: flex;
    //   flex-direction: column;
    //   justify-content: center;
    // }
  }
}

// Message Message Message Message Message Message Message Message Message Message Message Message
.message-row {
  display: flex;
  padding: 0.2rem 0.5rem;

  .message-content {
    font-family: "HelveticaNeue", sans-serif;
    font-size: $body-font-size-small;
    font-weight: 400;
    line-height: 1.2rem;
    padding: 0rem 0.5rem 1rem 0.5rem;

    flex: 0 1 auto;
    flex-basis: 90%;

    .message-author {
      font-weight: bold;
      font-size: $body-font-size;
    }

    .message-date {
      font-size: 0.7rem;
      text-align: right;
    }
  }

  &.left {
    flex-flow: row nowrap;
    color: $color-white;
    border-left: 3px solid $color-white;
  }

  &.right {
    flex-flow: row-reverse nowrap;
    //color: $color-bodytext-alpha;
    color: $color-white;
    //border-right: 3px solid $color-bodytext-alpha;
    border-right: 3px solid $color-white;

    .message-content {
      text-align: right;

      .message-author {
        //color: $color-bodytext-alpha;
        color: $color-white;
        text-align: right;
        text-transform: uppercase;
      }
    }
  }
}

.input-wrapper {
  padding: 1rem 0;

  .message-input-row {
    background-color: $color-white;

    display: flex;
    flex-flow: row nowrap;

    .input-container {
      flex: 1 0 auto;
    }

    .sendbutton-container {
      flex: 0 1 auto;
      flex-basis: 2rem;

      .sendbutton-button {
        padding-top: 0.5rem;
        width: 2rem;
        background: transparent;
        border: 0;
        &:hover {
          cursor: pointer;
        }

        img {
          width: 1.2rem;
        }
      }
    }

    input.input-message {
      width: 100%;
      background-color: $color-white;
      border: 0;
      border-radius: 0;
      padding: 0.5rem;
      margin: 0;
      font-size: $body-font-size;
      color: $color-text;
      word-break: break-word;
    }
  }
}

.attendeeprofile-wrapper {
  .separator-row {
    border-top: 2px solid $color-primary;
    height: 2px;
    width: 100%;
    margin: 0.5rem 0;
  }

  .attendeeprofile-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;

    .close-button {
      font-family: "HelveticaNeue", sans-serif;
      font-size: $body-font-size;

      color: $color-text;
      background-color: transparent;
      border: 0;
      padding: 0.5rem 0 1rem;
      text-transform: uppercase;

      display: flex;
      flex-direction: row;
      align-items: center;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }

      img {
        width: 1.5rem;
        margin: 0.5rem;
      }
    }
  }

  .attendeeprofile-content {
    display: flex;
    flex-flow: row nowrap;
    background-color: $color-gray;
    padding: 1rem;
  }

  .attendee-profilepic {
    padding-top: 1.5rem;
  }
  .attendee-details {
    padding-left: 1rem;

    .section-title {
      font-family: "HelveticaNeue", sans-serif;
      font-size: 1.2em;
      font-weight: normal;
      color: $color-primary;
      text-transform: uppercase;
      margin: 1.5rem 0 0.5rem 0;
    }
  }
}

.valuedisplay-container {
  background-color: $color-white;
  border-radius: 0.2rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;

  .valuedisplay-label {
    font-family: "HelveticaNeue", sans-serif;
    font-size: $body-font-size-small + 0.2rem;
    color: $color-text;
  }
  .valuedisplay-value {
    font-family: "HelveticaNeue", sans-serif;
    font-size: $body-font-size + 0.2rem;
    color: $color-text;
  }
}
