/*$body-font-size: 0.875rem;
$body-font-size-small: 0.625rem;*/
$body-font-size-xlarge: 1.875rem; //30px
$body-font-size-large: 1.312rem; //21px
$body-font-size: 1rem;
$body-font-size-small: 0.875rem; //14px
$body-font-size-xsmall: 0.8125rem; //13px
$body-font-size-xxsmall: 0.625rem; //10px

// Palette
$color-primary: #009dda;
$color-primary-alt: #00b8ff;
$color-darkblue: #00334c;
$color-lightblue: #85bbe6;
$color-magenta: #f52fa3;
$color-purple: #55448f;
$color-lily: #9A89D2;
$color-white: #ffffff;
$color-input: #e6e8ea;
$color-text: #6f7072;
$color-bodytext: #252525;
$color-bodytext-alpha: rgba(00, 00, 00, 0.6);
$color-bodytext-alpha-4: rgba(00, 00, 00, 0.2);
// $color-overlay: rgba(00, 145, 211, 0.9);
$color-overlay: rgba(20, 67, 144, 0.9);

$color-gray: #f1f1f1;
$color-lightgray: #fafafa;
$color-darkgray: #595859;
$color-mediumgray: #707070;
$color-orange: #e44c32;
//$color-yellow: #F8C200;
$color-yellow: #D88F07;
$color-shadow: rgba(0, 0, 0, 0.6);

$color-studi: #f49b00;
$color-enterprise: #0055b8;

//Rainbow
// $rc-1: #71bfed;
// $rc-2: #199ed9;
// $rc-3: #1e62ab;
// $rc-4: #c00b6c;
// $rc-5: #e82f71;
// $rc-6: #ec694b;
// $rc-7: #f8b334;
