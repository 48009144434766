// OVERRIDE SFONDO
.page-wrapper {
  &.test {
    .page-container {
      background-color: transparent;
      // background-image: url("/img/bg-red.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100vh;
      overflow-y: auto;
      display: block;
      .header-section {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;

        .headerImage {
          max-width: 70rem;
          margin: 0 auto;

          img {
            width: 100%;
          }
        }
      }

      .footer-section {
        width: 100%;

        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 3;

        .bottomImage {
          max-width: 70rem;
          margin: 0 auto;
          display: flex;
          flex-flow: column nowrap;
          justify-content: flex-end;

          img {
            width: 100%;
          }
        }
      }

      .page-content {
        z-index: 0;
        margin: 0 auto;

        &.redflix-themed {
          max-width: 70rem;
          color: $color-white;
          font-size: 1.1rem;
        }

        .center-area {
          &.redflix-themed {
            overflow: hidden;

            a {
              color: $color-white;
            }
          }

          .redflix-wrapper {
            padding: 22% 2rem 15% 2rem;
            z-index: 0;
          }
        }
      }
    }
  }
}
.back-area {
  max-width: 900px;
  width: 100%;
  display: flex;
  flex-direction: row;
  color: #fff;
  // text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);
  text-transform: uppercase;
  height: 2.5rem;
  // line-height: 3rem;
  // text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  align-items: center;
  img {
    width: 2.4rem;
  }
}

.redflix-wrapper {
  justify-content: flex-start;
  text-align: left;
  padding: 2rem;
  max-width: 900px;
  font-family: 'Helvetica Neue Bold', sans-serif;
  .redflix-header {
    //font-family: "Helvetica Neue Condensed", sans-serif;
    text-transform: uppercase;
    font-size: 2.9rem;
    line-height: 4.2rem;
    text-align: center;

    span {
      font-family: 'Helvetica Neue Bold', sans-serif;
      &.thin {
        font-family: 'Helvetica Neue', sans-serif;
      }
    }
  }
}
.questions-wrapper {
  justify-content: flex-start;
  text-align: left;
  padding: 3rem;
  max-width: 1190px;
  font-family: 'Helvetica Neue Bold', sans-serif;
  .question-container {
    background-color: #00548884;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 1rem;
    box-shadow: 10px 5px #ffffff81;
    &.has-answer {
      box-shadow: none;
    }
    .question-title {
      text-align: center;
      font-size: 2rem;
      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }
    .question-question {
      font-size: 1.5rem;
      line-height: 1.8rem;
      margin-bottom: 1rem;
    }
    .answer-row {
      font-weight: 400;
      &.correct {
        background-color: #00548884;
        .check-block {
          color: rgb(136, 254, 136);
        }
        .answer-block {
          color: rgb(136, 254, 136);
        }
      }
      &.wrong {
        text-decoration: line-through;
        .check-block {
          color: rgb(255, 2, 2);
        }
      }
      .check-block {
        text-align: right;
      }
      .answer-block {
        padding-top: 0.3rem;
        margin-bottom: 1.5rem;
      }
    }
  }
  .question-header {
    //font-family: "Helvetica Neue Condensed", sans-serif;
    text-transform: uppercase;
    font-size: 2.9rem;
    line-height: 4.2rem;
    text-align: center;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);

    span {
      font-family: 'Helvetica Neue Bold', sans-serif;
      &.thin {
        font-family: 'Helvetica Neue', sans-serif;
      }
    }
  }
}

.lesson-row-wrapper {
  margin: 0 0 3rem 0;
  display: block;
  h2 {
    padding: 1rem 0;
    text-transform: none;
    color: #005388;
    line-height: 2rem;
    text-align: center;
    font-weight: bold;
    font-size: 2.3rem;
    &.special {
      //color: $color-primary;
    }
  }
  .lesson-row {
    position: relative;
    .lesson-synopsis {
      padding-left: 2rem;
      color: #003f66;
      text-decoration: none;
      font-family: 'HelveticaNeue';
      font-weight: normal;
      font-size: 0.9rem;
      line-height: 2rem;
      margin-right: 2rem;
      ul {
        list-style-type: disc;
      }
    }

    .lesson-tools {
      max-width: 6.5rem;
      .lesson-duration {
        text-decoration: none;
        font-family: 'HelveticaNeue';
        font-weight: normal;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
        text-align: center;
      }
      img:hover {
        // webkit-filter: blur(1px); /* Chrome, Safari, Opera */
        filter: hue-rotate(90deg); // blur(1px);
      }
    }

    .lesson-item-wrapper {
      margin: 0;
      position: relative;
      // background: #fff1;
      // height: 4rem;
      border-radius: 0.5rem;

      .lesson-title {
        color: #005388;
        text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);

        text-decoration: none;
        font-family: 'HelveticaNeue';
        font-weight: bold;
        font-size: 2rem;
        line-height: 3rem;
      }
      .lesson-teacher {
        color: #005388;
        text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);
        text-decoration: none;
        font-family: 'HelveticaNeue';
        font-weight: bold;
        font-size: 1.2rem;
        line-height: 2rem;
      }
    }
  }
}
