@keyframes modal-show-right {
  0% {
    transform: translate(500rem);
    opacity: 0;
  }

  99% {
    transform: translate(0);
    opacity: 1;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes modal-hide-right {
  0% {
    transform: translate(0rem);
    opacity: 1;
  }

  99% {
    transform: translate(500rem);
    opacity: 0;
  }

  100% {
    transform: translate(500rem);
    opacity: 0;
  }
}

@keyframes modal-show-left {
  0% {
    transform: translate(-500rem);
    opacity: 0;
  }

  99% {
    transform: translate(0);
    opacity: 1;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes modal-hide-left {
  0% {
    transform: translate(0rem);
    opacity: 1;
  }

  99% {
    transform: translate(-500rem);
    opacity: 0;
  }

  100% {
    transform: translate(-500rem);
    opacity: 0;
  }
}

@keyframes big-modal-show {
  0% {
    transform: translate(2000rem);
    opacity: 0;
  }

  99% {
    transform: translate(0);
    opacity: 1;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes big-modal-hide {
  0% {
    transform: translate(0rem);
    opacity: 1;
  }

  99% {
    transform: translate(-2000rem);
    opacity: 0;
  }

  100% {
    transform: translate(-2000rem);
    opacity: 0;
  }
}