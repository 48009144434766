// OVERRIDE SFONDO
.page-wrapper {
  &.redflix {
    .page-container {
      background-color: transparent;
      // background-image: url("/img/bg-red.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100vh;
      overflow-y: auto;
      display: block;
      .header-section {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;

        .headerImage {
          max-width: 70rem;
          margin: 0 auto;

          img {
            width: 100%;
          }
        }
      }

      .footer-section {
        width: 100%;

        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 3;

        .bottomImage {
          max-width: 70rem;
          margin: 0 auto;
          display: flex;
          flex-flow: column nowrap;
          justify-content: flex-end;

          img {
            width: 100%;
          }
        }
      }

      .page-content {
        z-index: 0;
        margin: 0 auto;

        &.redflix-themed {
          max-width: 70rem;
          color: $color-white;
          font-size: 1.1rem;
        }

        .center-area {
          &.redflix-themed {
            overflow: hidden;

            a {
              color: $color-white;
            }
          }

          .redflix-wrapper {
            padding: 22% 2rem 15% 2rem;
            z-index: 0;
          }
        }
      }
    }
  }
}
.back-area {
  max-width: 900px;
  width: 100%;
  display: flex;
  flex-direction: row;
  color: #fff;
  // text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);
  text-transform: uppercase;
  height: 2.5rem;
  // line-height: 3rem;
  // text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  align-items: center;
  img {
    width: 2.4rem;
  }
}

.redflix-wrapper {
  justify-content: flex-start;
  text-align: left;
  padding: 2rem;
  max-width: 900px;
  font-family: 'Helvetica Neue Bold', sans-serif;
  .redflix-header {
    //font-family: "Helvetica Neue Condensed", sans-serif;
    text-transform: uppercase;
    font-size: 2.9rem;
    line-height: 4.2rem;
    text-align: center;

    span {
      font-family: 'Helvetica Neue Bold', sans-serif;
      &.thin {
        font-family: 'Helvetica Neue', sans-serif;
      }
    }
  }
}
.lessons-wrapper {
  justify-content: flex-start;
  text-align: left;
  padding: 3rem;
  max-width: 1190px;
  font-family: 'Helvetica Neue Bold', sans-serif;

  .lessons-header {
    //font-family: "Helvetica Neue Condensed", sans-serif;
    text-transform: uppercase;
    font-size: 2.9rem;
    line-height: 4.2rem;
    text-align: center;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);

    span {
      font-family: 'Helvetica Neue Bold', sans-serif;
      &.thin {
        font-family: 'Helvetica Neue', sans-serif;
      }
    }
  }
}

.lesson-row-wrapper {
  margin: 0 0 3rem 0;
  display: block;
  h2 {
    padding: 1rem 0;
    text-transform: none;
    color: #005388;
    line-height: 2rem;
    text-align: center;
    font-weight: bold;
    font-size: 2.3rem;
    &.special {
      //color: $color-primary;
    }
  }
  .lesson-row {
    position: relative;
    .lesson-synopsis {
      padding-left: 2rem;
      color: #003f66;
      text-decoration: none;
      font-family: 'HelveticaNeue';
      font-weight: normal;
      font-size: 0.9rem;
      line-height: 2rem;
      margin-right: 2rem;
      ul {
        list-style-type: disc;
      }
    }

    .lesson-tools {
      max-width: 6.5rem;
      .lesson-duration {
        text-decoration: none;
        font-family: 'HelveticaNeue';
        font-weight: normal;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
        text-align: center;
      }
      img:hover {
        // webkit-filter: blur(1px); /* Chrome, Safari, Opera */
        filter: hue-rotate(90deg); // blur(1px);
      }
    }

    .lesson-item-wrapper {
      margin: 0;
      position: relative;
      // background: #fff1;
      // height: 4rem;
      border-radius: 0.5rem;

      .lesson-title {
        color: #005388;
        text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);

        text-decoration: none;
        font-family: 'HelveticaNeue';
        font-weight: bold;
        font-size: 2rem;
        line-height: 3rem;
      }
      .lesson-teacher {
        color: #005388;
        text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);
        text-decoration: none;
        font-family: 'HelveticaNeue';
        font-weight: bold;
        font-size: 1.2rem;
        line-height: 2rem;
      }
    }
  }
}

.video-row-wrapper {
  margin: 0 0 3rem 0;
  display: block;
  h2 {
    padding: 1rem 0;
    text-transform: none;
    color: #005388;
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);
    line-height: 2rem;
    text-align: center;
    font-weight: bold;
    font-size: 2.3rem;
    &.special {
      //color: $color-primary;
    }
  }

  .video-row {
    position: relative;
    display: block;

    .content-item-wrapper {
      margin: 0;
      position: relative;
      background: #fff1;
      height: 4rem;
      border-radius: 0.5rem;

      .title {
        position: absolute;
        display: block;
        top: 0.5rem;
        left: 3rem;
        color: #005388;
        text-decoration: none;
        font-family: 'HelveticaNeue';
        font-weight: bold;
        font-size: 2rem;
        line-height: 3rem;
      }
      .sub-title {
        position: absolute;
        display: block;
        right: 0.1rem;
        bottom: 0.5rem;
        color: #005388;
        text-decoration: none;
        font-family: 'HelveticaNeue';
        font-weight: normal;
        font-size: 0.9rem;
        line-height: 2rem;
      }

      .tools {
        position: absolute;
        top: 0.75rem;
        left: 0.25rem;

        // .play,
        // .download {
        //   &:hover {
        //     cursor: pointer;
        //   }
        // }

        img {
          width: 2.5rem;
          color: black;
        }
      }
    }
    .video-item-wrapper {
      margin: 0;
      display: block;

      position: relative;

      .title {
        position: absolute;
        display: block;
        top: 8px;
        left: 16px;

        text-decoration: none;
        font-family: 'HelveticaNeue';
        font-weight: bold;
        font-size: 2rem;
        line-height: 3rem;
      }

      .sub-title {
        position: absolute;
        display: block;
        bottom: 20px;
        left: 16px;
        text-decoration: none;
        font-family: 'HelveticaNeue';
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 2rem;
      }

      img {
        display: block;
        width: 100%;
        border-radius: 0.5rem;
      }

      &:hover {
        img {
          // margin-left: -0.3rem;
          // margin-left: 0;
          width: 100%;
        }

        .title {
          cursor: pointer;
          color: $color-white;
          text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);
          //margin-top: -0.2rem;
        }

        .sub-title {
          cursor: pointer;
          color: $color-white;
          text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);
        }

        .tools {
          img {
            color: white;
          }
        }
      }

      .tools {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        // .play,
        // .download {
        //   &:hover {
        //     cursor: pointer;
        //   }
        // }

        img {
          width: 6rem;
          color: black;
        }
      }
    }
  }
}

.video-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.65);
  // display: block;
  // flex-direction: row nowrap;
  // align-items: center;
  // justify-content: center;

  .nopadding {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 2000;

    &:hover {
      cursor: pointer;
    }
  }
}
